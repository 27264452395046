import suppliersService from "@/services/suppliers/suppliers.service";
import itemTypesService from "@/services/itemTypes/itemTypes.service";
import departmentsService from "@/services/departments/departments.service";
export default [
  {
    name: "internalCode",
    type: "string",
    text: "Codice Interno",
    icon: "mdi-code-tags",
  },
  {
    name: "code",
    type: "string",
    text: "Barcode",
    icon: "mdi-barcode",
  },
  {
    name: "description",
    type: "string",
    text: "Descrizione",
    icon: "mdi-clipboard-text",
  },
  {
    name: "supplierId",
    type: "object",
    text: "Fornitori",
    icon: "mdi-account-multiple",
    fieldToShow: "businessName",
    fetcher: suppliersService.list,
  },
  {
    name: "itemTypeId",
    type: "object",
    text: "Famiglia",
    icon: "mdi-spray-bottle",
    fieldToShow: "name",
    fetcher: itemTypesService.list,
  },
  {
    name: "departmentId",
    type: "object",
    text: "Dipartimento",
    icon: "mdi-home-city",
    fieldToShow: "name",
    fetcher: departmentsService.list,
  },
  {
    name: "qtyInStock",
    type: "boolean",
    text: "Disponibili a magazzino",
    icon: "",
  },
  {
    name: "createdAt",
    type: "date",
    text: "Data Creazione",
    icon: "mdi-calendar",
  },
];
