<template>
  <div>
    <div class="font-italic font-weight-light">
      Seleziona prodotti da importare
    </div>

    <ItemToImportTable
      :single-select="false"
      :height="dataTableHeight"
      :show-select="true"
      item-key="key"
      fixed-header
      class="mt-1"
      :items="localItemsToImport"
      :headers="headers"
      :page.sync="page"
      :row-per-page="rowsNumberToDisplay"
      :rowPerPageOptions="rowPerPageOptions"
      :total-pages.sync="totalPages"
      :show-actions="false"
      item-selected
      :disabledPagination="true"
      loading-text="Caricamento prodotti da importare ..."
      :loading="loading"
      @input="handleSelection"
    ></ItemToImportTable>
  </div>
</template>

<script>
import ItemToImportTable from "@/components/common/TypeDataTable.vue";
export default {
  name: "UploadingItemList",
  components: {
    ItemToImportTable,
  },
  props: {
    itemsToImport: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      totalPages: 1,
      dataTableHeightValue: 340,
      itemsSelectedToImport: undefined,
      localItemsToImport: undefined,
      rowsNumberToDisplay: 0,
      rowPerPageOptions: [],
      headers: [
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Quantità da Importare",
          value: "qtyToImport",
        },
        {
          text: "Prezzo di Vendita",
          value: "priceSelling",
        },
        {
          text: "Prezzo d'acquisto",
          value: "pricePurchase",
        },
        {
          text: "Prezzo Netto d'acquisto",
          value: "priceNetPurchase",
        },
        {
          text: "Uso Interno",
          value: "isInternal",
        },
        {
          text: "Categoria Tag",
          value: "tagCategory",
        },
        {
          text: "Tag",
          value: "tag",
        },
        {
          text: "Confezione",
          value: "package_types",
        },
        {
          text: "Fornitore",
          value: "suppliers",
        },
        {
          text: "Dipartimento",
          value: "departments",
        },
        {
          text: "Unità Di Misura",
          value: "unit_of_measures",
        },
        {
          text: "Codici associati",
          value: "associated_codes",
        }
      ],
    };
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  mounted: function () {
      this.loading = true
      this.localItemsToImport = this.itemsToImport;
      this.rowsNumberToDisplay = this.localItemsToImport.length
      this.rowPerPageOptions.push(this.rowsNumberToDisplay)
      this.loading = false;
  },

  methods: {
    handleSelection(itemsSelected) {
      this.itemsSelectedToImport = itemsSelected;
      this.$emit("items-seleted-to-import", itemsSelected);
    },
  },
};
</script>

<style>
</style>